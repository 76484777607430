import React from "react"
import PropTypes from "prop-types"

import Button from "../button/button"

import useEventListener from "../../hooks/use-event-listener"
import useLocale from "../../hooks/use-locale"

import { classnames } from "../../utils/helpers"

import styles from "./footer.module.scss"

const BASE_WIDTH = 1440,
  BASE_HEIGHT = 262,
  PADDING = 20,
  DOT_OFFSET = 5,
  DOT_RADIUS = 3,
  DOT_DISTANCE = 15

let angleColor

const drawAngle = (element, color) => {
  const canvas = element,
    ctx = canvas.getContext("2d")

  const ratio = BASE_HEIGHT / BASE_WIDTH

  canvas.width = window.innerWidth
  canvas.height = Math.min(window.innerWidth * ratio, BASE_HEIGHT)

  // the + 4 helps avoid rounding issues where we can see a small transparent line
  element.style.top = `${-canvas.height + 4}px`

  // the angled shape
  const r = Math.atan2(ctx.canvas.height - PADDING, ctx.canvas.width)

  ctx.fillStyle = color
  ctx.beginPath()
  ctx.moveTo(0, canvas.height - PADDING)
  ctx.lineTo(canvas.width, 0)
  ctx.lineTo(canvas.width, canvas.height + PADDING)
  ctx.lineTo(0, canvas.height + PADDING)
  ctx.closePath()
  ctx.fill()

  // ze dots
  ctx.fillStyle = "white"
  let x = DOT_DISTANCE * -0.5

  for (x; x < canvas.width + PADDING; x += DOT_DISTANCE) {
    let y = canvas.height - DOT_OFFSET - x * r

    ctx.beginPath()
    ctx.arc(x, y, DOT_RADIUS, 0, Math.PI * 2)
    ctx.closePath()
    ctx.fill()
  }
}

const Footer = ({ children }) => {
  const footerDeco = React.useRef()
  const { globalLocale } = useLocale()

  React.useEffect(() => {
    angleColor = getComputedStyle(footerDeco.current).getPropertyValue("--bg")
    drawAngle(footerDeco.current, angleColor)
  }, [])

  useEventListener("resize", () => {
    drawAngle(footerDeco.current, angleColor)
  })

  return (
    <footer className={classnames([styles.FooterMain, "txt-center"])}>
      <canvas
        className={styles.FooterMain__angle}
        ref={footerDeco}
        width={BASE_WIDTH}
        height={BASE_HEIGHT}
        style={{ maxHeight: `${BASE_HEIGHT}px` }}
        aria-hidden="true"
      />
      <div className={styles.FooterMain__content}>
        {children}
        <Button
          to={globalLocale.footer.customerSupport.url}
          rel="noopener nofollow noreferrer"
          target="_blank"
          withFrame={true}
          flat={true}
          arrow={true}
          className={styles.FooterMain__customer}
        >
          {globalLocale.footer.customerSupport.label}
        </Button>
        <div className={styles.FooterMain__nclood} id="nclood-footer"></div>

        {globalLocale.footer.legal.map((para, i) => (
          <p className="txt-tiny" key={`footer-legal-${i}`}>
            {para}
          </p>
        ))}
      </div>
    </footer>
  )
}

Footer.propTypes = {
  children: PropTypes.node,
}

export default Footer
