import React, { useState, useRef, useEffect, createContext } from "react"
import PropTypes from "prop-types"

/**
 * NcloodContext
 * Provides the current logged user, user token and status of nclood and alps apis.
 * You are problably not gonna need the token for now untill we use the mission api.
 *
 * Values:
 * user: Object
 * token: String
 * error: Bool
 * loaded: Bool
 *
 * Example:
 * const {error, loaded, user} = useContext(NcloodContext);
 * if (error) return <p>Nclood or Alps failed to load</p>
 * if (loaded) return <p>Nclood and Alps loaded, ready to use metrics, etc</p>
 * if (user) return <p>Logged in as {user.data.nickname}</p>
 */
const NcloodContext = createContext()

const NcloodProvider = function({ children }) {
  const [isLoaded, setIsLoaded] = useState(false)
  const [isError, setIsError] = useState(false)
  const [user, setUser] = useState(null)
  let checking = useRef(false)
  let timer = useRef(null)
  let timerAccounts = useRef(null)

  // Effect to check the APIs status
  useEffect(() => {
    if (checking.current || isLoaded) return

    async function checkApi() {
      try {
        const loaded = await new Promise((resolve, reject) => {
          const startTime = Date.now()
          const checkLoop = function() {
            if (!window.Alps && !window.nclood) {
              const now = Date.now()
              if ((now - startTime) / 1000 > 6) reject(false)
              else {
                clearTimeout(timer.current)
                timer.current = setTimeout(() => {
                  checkLoop()
                }, 500)
              }
            } else resolve(true)
          }

          checkLoop()
        })

        return loaded
      } catch (err) {
        throw new Error(err)
      } finally {
        clearTimeout(timer.current)
      }
    }

    async function checkAccounts() {
      try {
        const loaded = await new Promise((resolve, reject) => {
          const startTime = Date.now()
          const checkLoop = function() {
            if (!window.nclood.Accounts.loaded) {
              const now = Date.now()
              if ((now - startTime) / 1000 > 6) reject(false)
              else {
                clearTimeout(timerAccounts.current)
                timerAccounts.current = setTimeout(() => {
                  checkLoop()
                }, 500)
              }
            } else resolve(true)
          }

          checkLoop()
        })

        return loaded
      } catch (err) {
        throw new Error(err)
      } finally {
        clearTimeout(timerAccounts.current)
      }
    }

    async function defineState() {
      try {
        const apiLoaded = await checkApi()

        if (!apiLoaded) throw new Error("Timed out")

        const accountsLoaded = await checkAccounts()

        if (!accountsLoaded) throw new Error("Timed out")

        let currentUser = window.nclood.Accounts.currentUser

        if (currentUser) setUser(currentUser)

        window.nclood.Accounts.onLogin(currentUser => {
          setUser(currentUser)
        })

        window.nclood.Accounts.onLogout(() => {
          setUser(null)
        })

        setIsLoaded(true)
      } catch (err) {
        setIsError(err)
      }
    }

    checking.current = true
    defineState()
  }, [isLoaded])

  return (
    <NcloodContext.Provider
      value={{
        user,
        error: isError,
        loaded: isLoaded,
      }}
    >
      {children}
    </NcloodContext.Provider>
  )
}

NcloodProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export { NcloodContext as default, NcloodProvider }
