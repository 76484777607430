// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-ca-js": () => import("./../../../src/pages/about/index.ca.js" /* webpackChunkName: "component---src-pages-about-index-ca-js" */),
  "component---src-pages-about-index-en-js": () => import("./../../../src/pages/about/index.en.js" /* webpackChunkName: "component---src-pages-about-index-en-js" */),
  "component---src-pages-about-index-es-js": () => import("./../../../src/pages/about/index.es.js" /* webpackChunkName: "component---src-pages-about-index-es-js" */),
  "component---src-pages-about-index-fr-js": () => import("./../../../src/pages/about/index.fr.js" /* webpackChunkName: "component---src-pages-about-index-fr-js" */),
  "component---src-pages-characters-index-ca-js": () => import("./../../../src/pages/characters/index.ca.js" /* webpackChunkName: "component---src-pages-characters-index-ca-js" */),
  "component---src-pages-characters-index-en-js": () => import("./../../../src/pages/characters/index.en.js" /* webpackChunkName: "component---src-pages-characters-index-en-js" */),
  "component---src-pages-characters-index-es-js": () => import("./../../../src/pages/characters/index.es.js" /* webpackChunkName: "component---src-pages-characters-index-es-js" */),
  "component---src-pages-characters-index-fr-js": () => import("./../../../src/pages/characters/index.fr.js" /* webpackChunkName: "component---src-pages-characters-index-fr-js" */),
  "component---src-pages-history-index-ca-js": () => import("./../../../src/pages/history/index.ca.js" /* webpackChunkName: "component---src-pages-history-index-ca-js" */),
  "component---src-pages-history-index-en-js": () => import("./../../../src/pages/history/index.en.js" /* webpackChunkName: "component---src-pages-history-index-en-js" */),
  "component---src-pages-history-index-es-js": () => import("./../../../src/pages/history/index.es.js" /* webpackChunkName: "component---src-pages-history-index-es-js" */),
  "component---src-pages-history-index-fr-js": () => import("./../../../src/pages/history/index.fr.js" /* webpackChunkName: "component---src-pages-history-index-fr-js" */),
  "component---src-pages-index-ca-js": () => import("./../../../src/pages/index.ca.js" /* webpackChunkName: "component---src-pages-index-ca-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-es-js": () => import("./../../../src/pages/index.es.js" /* webpackChunkName: "component---src-pages-index-es-js" */),
  "component---src-pages-index-fr-js": () => import("./../../../src/pages/index.fr.js" /* webpackChunkName: "component---src-pages-index-fr-js" */),
  "component---src-pages-news-index-ca-js": () => import("./../../../src/pages/news/index.ca.js" /* webpackChunkName: "component---src-pages-news-index-ca-js" */),
  "component---src-pages-news-index-en-js": () => import("./../../../src/pages/news/index.en.js" /* webpackChunkName: "component---src-pages-news-index-en-js" */),
  "component---src-pages-news-index-es-js": () => import("./../../../src/pages/news/index.es.js" /* webpackChunkName: "component---src-pages-news-index-es-js" */),
  "component---src-pages-news-index-fr-js": () => import("./../../../src/pages/news/index.fr.js" /* webpackChunkName: "component---src-pages-news-index-fr-js" */),
  "component---src-pages-quiz-index-ca-js": () => import("./../../../src/pages/quiz/index.ca.js" /* webpackChunkName: "component---src-pages-quiz-index-ca-js" */),
  "component---src-pages-quiz-index-en-js": () => import("./../../../src/pages/quiz/index.en.js" /* webpackChunkName: "component---src-pages-quiz-index-en-js" */),
  "component---src-pages-quiz-index-es-js": () => import("./../../../src/pages/quiz/index.es.js" /* webpackChunkName: "component---src-pages-quiz-index-es-js" */),
  "component---src-pages-quiz-index-fr-js": () => import("./../../../src/pages/quiz/index.fr.js" /* webpackChunkName: "component---src-pages-quiz-index-fr-js" */)
}

