import React, { useReducer, createContext } from "react"
import PropTypes from "prop-types"

import storage from "../utils/storage"
import { isServer } from "../utils/consts"
import { useEffect } from "react"

const KEY = "wtc-prefersReducedMotion"
const ReduceMotionContext = createContext()

const MATCH_MEDIA = function() {
  if (isServer) return null
  return window.matchMedia("(prefers-reduced-motion: reduce)").matches
}

const toggleHtmlClass = function(state) {
  if (!isServer) {
    const className = "is-reduced-motion"
    const html = document.querySelector("html")
    if (state === 1) html.classList.add(className)
    else html.classList.remove(className)
  }
}

const reduceMotionReducer = function(state, action) {
  switch (action.type) {
    case "on": {
      toggleHtmlClass(1)
      storage.setItem(KEY, 1)
      return 1
    }
    case "off": {
      toggleHtmlClass(0)
      storage.setItem(KEY, 0)
      return 0
    }
    default: {
      return state
    }
  }
}

const getInitState = () => {
  const MQ = MATCH_MEDIA()
  const init = parseInt(MQ ? 1 : storage.getItem(KEY) || 0)

  return init
}

const ReduceMotionProvider = function({ children }) {
  const [state, dispatch] = useReducer(reduceMotionReducer, 0)

  useEffect(() => {
    const init = getInitState()
    if (init === 1) dispatch({ type: "on" })
    else dispatch({ type: "off" })
  }, [])

  return (
    <ReduceMotionContext.Provider value={[state, dispatch, getInitState]}>
      {children}
    </ReduceMotionContext.Provider>
  )
}

ReduceMotionProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export { ReduceMotionContext as default, ReduceMotionProvider }
