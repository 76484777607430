// Snapshot of the possible types in graph,
// This is needed so that Apollo knows which types are possible
// for fragments and unions: https://www.apollographql.com/docs/react/v2/data/fragments/#fragments-on-unions-and-interfaces

export default {
  __schema: {
    types: [
      {
        kind: "INTERFACE",
        name: "ContentEntry",
        possibleTypes: [
          { name: "Amiibo" },
          { name: "Background" },
          { name: "ContentDescriptor" },
          { name: "ContentfulAsset" },
          { name: "ContentRating" },
          { name: "CtaDestination" },
          { name: "MaintenanceWindow" },
          { name: "Media" },
          { name: "MediaGallery" },
          { name: "NewsArticle" },
          { name: "NintendoDirectHeadline" },
          { name: "NintendoDirect" },
          { name: "SEOMetadata" },
          { name: "Page" },
        ],
      },
    ],
  },
}
