// save consts that aren't necessary enviroment variables here
export const isServer = typeof window === "undefined"

export const COMMON_KEYS = {
  DOWN: 40,
  UP: 38,
  ESC: 27,
  ENTER: 13,
  SPACE: 32,
  TAB: 9,
}

// These values com from /scss/variables/variables-breakpoints.scss
// Any updates should be reflected here
export const BREAKPOINTS = {
  small: "25em",
  medium: "40em",
  large: "64em",
  xlarge: "71.5em",
  xxlarge: "120em",
}

export const NEWS_LOCALE_MAP = {
  en: "en_US",
  ca: "en_CA",
  fr: "fr_CA",
  es: "es_MX",
}
