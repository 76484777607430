import { useRef, useEffect } from "react"

export default function useEventListener(eventName, handler, element) {
  const isClient = typeof window === "object"
  // Create a ref that stores handler
  const savedHandler = useRef()
  const savedElement = useRef(element)
  const throtle = useRef()

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(
    () => {
      if (!isClient) return
      if (!savedElement.current) savedElement.current = window
      // Make sure element supports addEventListener
      // On
      const isSupported =
        savedElement.current && savedElement.current.addEventListener
      if (!isSupported) return

      // Create event listener that calls handler function stored in ref
      const eventListener = event => {
        clearTimeout(throtle.current)
        throtle.current = setTimeout(() => {
          savedHandler.current(event)
        }, 300)
      }

      // Add event listener
      savedElement.current.addEventListener(eventName, eventListener)

      // Remove event listener on cleanup
      return () => {
        clearTimeout(throtle.current)
        savedElement.current.removeEventListener(eventName, eventListener)
      }
    },
    [eventName, isClient] // Re-run if eventName or element changes
  )
}
