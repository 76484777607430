import React, { forwardRef } from "react"
import PropTypes from "prop-types"

import LocalizedLink from "../localized-link/localized-link"
import Icon from "../icon/icon"

import { classnames } from "../../utils/helpers"

import styles from "./button.module.scss"
import useReduceMotion from "../../hooks/use-reduce-motion"
import Image from "../image/image"

const ButtonContent = function({
  children,
  arrow,
  arrowDown,
  arrowPlay,
  withFrame,
  characterIcon,
}) {
  return (
    <>
      {withFrame && (
        <>
          <div
            className={classnames([
              styles.Button__frame,
              styles["Button__frame--top"],
            ])}
          />
          <div
            className={classnames([
              styles.Button__frame,
              styles["Button__frame--bottom"],
            ])}
          />
        </>
      )}
      {characterIcon && (
        <Image
          wrapperClassName={classnames([
            // "img-block",
            styles.characterIcon,
          ])}
          className={classnames([])}
          alt=""
          {...characterIcon}
        />
      )}
      <span className={styles.Button__content}>{children}</span>
      {arrowPlay && <Icon id="icon-play" className={styles.Button__arrow} />}
      {arrow && <Icon id="text-arrow" className={styles.Button__arrow} />}
      {arrowDown && (
        <Icon id="text-arrow" className={styles.Button__arrow_down} />
      )}
    </>
  )
}

ButtonContent.propTypes = {
  children: PropTypes.node,
  arrow: PropTypes.bool,
  arrowDown: PropTypes.bool,
  arrowPlay: PropTypes.bool,
  withFrame: PropTypes.bool,
  characterIcon: PropTypes.shape({
    src: PropTypes.string,
  }),
}

const Button = forwardRef(function Button(
  {
    tag,
    small,
    className,
    flat,
    color,
    to = "",
    arrow,
    arrowDown,
    arrowPlay,
    withFrame,
    children,
    characterIcon,
    hiddenText,
    ...props
  },
  ref
) {
  const Tag = to ? "a" : tag
  const [rmState] = useReduceMotion()
  const allClassNames = classnames([
    styles.Button,
    styles[`Button--${color}`],
    small && styles["Button--small"],
    flat && styles["Button--flat"],
    withFrame && styles["Button--frame"],
    arrow && styles["Button--arrow"],
    arrowDown && styles["Button--arrow-down"],
    rmState === 1 && styles["is-reduced-motion"],
    className,
  ])

  if (to && to.indexOf("http") < 0 && to.indexOf("//") < 0)
    return (
      <LocalizedLink className={allClassNames} ref={ref} to={to} {...props}>
        <ButtonContent
          arrow={arrow}
          arrowDown={arrowDown}
          arrowPlay={arrowPlay}
          withFrame={withFrame}
          characterIcon={characterIcon}
        >
          {hiddenText ? (
            <>
              <span aria-hidden="true">{children}</span>
              <span className="visually-hidden">{hiddenText}</span>
            </>
          ) : (
            children
          )}
        </ButtonContent>
      </LocalizedLink>
    )

  return (
    <Tag className={allClassNames} ref={ref} href={to} {...props}>
      <ButtonContent
        arrow={arrow}
        arrowDown={arrowDown}
        arrowPlay={arrowPlay}
        withFrame={withFrame}
        characterIcon={characterIcon}
      >
        {hiddenText ? (
          <>
            <span aria-hidden="true">{children}</span>
            <span className="visually-hidden">{hiddenText}</span>
          </>
        ) : (
          children
        )}
      </ButtonContent>
    </Tag>
  )
})

Button.defaultProps = {
  tag: "button",
  small: false,
  flat: false,
  color: "white",
  to: null,
  arrow: false,
  arrowPlay: false,
  withFrame: false,
}

Button.propTypes = {
  arrow: PropTypes.bool,
  arrowDown: PropTypes.bool,
  tag: PropTypes.string,
  small: PropTypes.bool,
  flat: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  to: PropTypes.string,
  color: PropTypes.oneOf(["red", "white", "yellow", "black", "dark-grey"]),
  withFrame: PropTypes.bool,
  arrowPlay: PropTypes.bool,
  characterIcon: PropTypes.shape({
    src: PropTypes.string,
  }),
  hiddenText: PropTypes.string,
}

export default Button
