import React, { createContext } from "react"

const LocaleContext = createContext()

const LocaleProvider = function({ children, langKey }) {
  return (
    <LocaleContext.Provider value={langKey || "en"}>
      {children}
    </LocaleContext.Provider>
  )
}

export { LocaleContext as default, LocaleProvider }
