import React from "react"
import { ApolloProvider } from "@apollo/react-hooks"
import ApolloClient, {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from "apollo-boost"
import fetch from "cross-fetch"
import possibleTypes from "./possible-types"

const client = new ApolloClient({
  uri: "https://graph.nintendo.com",
  fetch,
  cache: new InMemoryCache({
    fragmentMatcher: new IntrospectionFragmentMatcher({
      introspectionQueryResultData: possibleTypes,
    }),
  }),
})

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>{element}</ApolloProvider>
)
