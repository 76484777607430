import { isServer } from "./consts.js";

// Okay, why are we creating a "copy" of localStorage?
// Well, our app is server side rendered there isn't a
// localStorage on the server and that causes tons of
// issues when pre-rendering the pages, this util file
// helps with that problem. You don't have to use
// this if the code you are making will ONLY run in the client.
// But you SHOULD use it, it will help avoid errors in the future.
const storage = {
  setItem(key, data) {
    if (isServer) return null;
    return localStorage.setItem(key, data);
  },
  getItem(key) {
    if (isServer) return null;
    return localStorage.getItem(key);
  }
};

export default storage;
