import React, { useContext } from "react"
import { Link } from "gatsby"

import LocaleContext from "../../contexts/locale-context"

const LocalizedLink = function({ to, ...props }) {
  const langKey = useContext(LocaleContext)
  to = langKey !== "en" ? `/${langKey}${to}` : to

  return <Link to={to} {...props} />
}

export default LocalizedLink
