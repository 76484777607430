import React from "react"

const SVGIncludes = () => {
  return (
    <div style={{ display: "none" }}>
      <svg>
        {/* right-arrow for text buttons */}
        <symbol id="icon-text-arrow" viewBox="0 0 13 13">
          <path
            stroke="currentColor"
            strokeWidth="4"
            d="M4.867 2L9 6.292l-4.133 4.362"
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </symbol>

        {/* check mark */}
        <symbol id="icon-checkmark" viewBox="0 0 25 25">
          <path
            fill="none"
            fillRule="evenodd"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
            d="M2 13l8 7M10 20L23 5"
          />
        </symbol>

        {/* fat checkmark */}
        <symbol id="icon-fat-checkmark" viewBox="0 0 38 38">
          <g
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="#FFF" strokeWidth="16" d="M9 20l8 7M17 27l13-15" />
            <path
              stroke="currentColor"
              strokeWidth="6"
              d="M9 20l8 7M17 27l13-15"
            />
          </g>
        </symbol>

        {/* fat arrow */}
        <symbol id="icon-fat-arrow" viewBox="0 0 38 38">
          <g
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="#FFF" strokeWidth="16" d="M8 13l11 11 11-11" />
            <path stroke="currentColor" strokeWidth="6" d="M8 13l11 11 11-11" />
          </g>
        </symbol>

        {/* fat arrow, version 2 */}
        <symbol id="icon-fat-arrow-2" viewBox="0 0 22 22">
          <path
            stroke="currentColor"
            strokeWidth="4"
            d="M7 19.485L15.485 11 7 2.515"
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </symbol>

        {/* lock */}
        <symbol id="icon-lock" viewBox="0 0 38 38">
          <path
            d="M19.493 1c3.492 0 6.667 1.524 8.879 3.899 2.164 2.314 3.534 6.142 3.534 9.608h0v.24a3.563 3.563 0 012.046 1.01A3.556 3.556 0 0135 18.287h0V33.42c0 .987-.4 1.88-1.046 2.528A3.565 3.565 0 0131.425 37h0-23.85a3.56 3.56 0 01-2.53-1.05A3.57 3.57 0 014 33.422h0V18.286c0-.984.4-1.88 1.05-2.527a3.565 3.565 0 012.044-1.013h0V14.5c0-3.466 1.37-7.294 3.534-9.609C12.842 2.522 16.018 1 19.493 1zm-.406 8.103c-1.187 0-2.318.567-3.277 1.6-.85 1.046-1.265 2.73-1.265 4.246l9.091-.158c0-1.428-.445-3.228-1.391-4.263-.83-.888-1.97-1.425-3.158-1.425z"
            stroke="#FFF"
            strokeWidth="4"
            fill="#E60012"
            fillRule="evenodd"
          />
        </symbol>

        {/* fat x */}
        <symbol id="icon-fat-x" viewBox="0 0 19 19">
          <g
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="#FFF" strokeWidth="8" d="M4 4l11 11M4 15L15 4" />
            <path stroke="#E60012" strokeWidth="3" d="M4 4l11 11M4 15L15 4" />
          </g>
        </symbol>

        {/* exlamation mark box */}
        <symbol id="icon-alert" viewBox="0 0 36 36">
          <g transform="translate(2 2)" fill="none" fillRule="evenodd">
            <rect
              stroke="#FFF"
              strokeWidth="2"
              fill="#0055BF"
              x="-1"
              y="-1"
              width="34"
              height="34"
              rx="4"
            />
            <path
              d="M18.002 20.176l.56-14.168h-5.096l.56 13.048 3.976 1.12zM16.014 26c.71 0 1.311-.243 1.806-.728.495-.485.742-1.092.742-1.82s-.247-1.34-.742-1.834a2.458 2.458 0 00-1.806-.742c-.728 0-1.34.247-1.834.742-.495.495-.742 1.106-.742 1.834s.247 1.335.742 1.82c.495.485 1.106.728 1.834.728z"
              fill="#FFF"
              fillRule="nonzero"
            />
          </g>
        </symbol>

        {/* sound off */}
        <symbol id="icon-sound-off" viewBox="0 0 28 28">
          <path
            d="m14.908 4.403a.87.87 0 0 1 .135.467v17.7a.87.87 0 0 1 -1.336.734l-8.229-5.237h-2.608a.87.87 0 0 1 -.87-.87v-6.956c0-.48.39-.87.87-.87h2.608l8.23-5.236a.87.87 0 0 1 1.2.267zm2.257 4.917c.509-.51 1.335-.51 1.844 0l2.556 2.555 2.556-2.555a1.304 1.304 0 0 1 1.934 1.745l-.09.1-2.556 2.555 2.557 2.556.09.1a1.305 1.305 0 0 1 -1.935 1.745l-2.556-2.557-2.556 2.557a1.304 1.304 0 0 1 -1.934-1.746l.09-.099 2.555-2.556-2.555-2.556-.09-.099a1.305 1.305 0 0 1 .09-1.745z"
            fill="currentColor"
          />
        </symbol>

        <symbol id="icon-sound-on" viewBox=" 0 0 28 28">
          <path
            d="M14.908 4.403a.87.87 0 01.135.467V22.57a.87.87 0 01-1.336.734l-8.229-5.237H2.87a.87.87 0 01-.87-.87v-6.956c0-.48.39-.87.87-.87h2.608l8.23-5.236a.87.87 0 011.2.267zm6.5.221a10.86 10.86 0 014.918 9.096 10.86 10.86 0 01-4.926 9.102 1.304 1.304 0 11-1.428-2.183 8.251 8.251 0 003.745-6.919 8.251 8.251 0 00-3.739-6.914 1.304 1.304 0 011.43-2.182zm-2.516 4.608a5.644 5.644 0 012.216 4.488 5.644 5.644 0 01-2.23 4.5 1.304 1.304 0 01-1.677-1.995l.096-.08a3.036 3.036 0 001.202-2.425c0-.896-.388-1.725-1.048-2.298l-.146-.12a1.304 1.304 0 011.587-2.07z"
            fill="currentColor"
            fillRule="evenodd"
          />
        </symbol>

        <symbol id="icon-play" viewBox=" 0 0 21 21">
          <path d="m10.24 2.435 8.711 6.247a2 2 0 0 1 0 3.25l-8.71 6.247a2 2 0 0 1-3.166-1.625V4.06a2 2 0 0 1 3.166-1.625Z" />
        </symbol>
      </svg>
    </div>
  )
}

export default SVGIncludes
