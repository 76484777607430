import { graphql } from "gatsby"
import { isServer } from "./consts"

/**
 * Returns an object with the specified string as the `__html` property.
 * This is to be used in conjunction with the `dangerouslySetInnerHTML` prop.
 * @param {string} markupString the string of markup to use.
 */
export const createMarkup = markupString => ({ __html: markupString })

/**
 * returns an image tag with the ESRB icon
 * @param {string} rating the rating string, such as 'e', 'e10', 't', 'm', etc.
 */
export const getIconESRB = rating => {
  return `https://media.nintendo.com/share/nclood/stable/en-us/modules/footer/images/esrb-icons/${rating}.svg`
}

/**
 * returns a trimmed string without empty classnames
 * @param {array} classnames
 */
export const classnames = namesArray =>
  namesArray
    .filter(v => v !== "" && v !== undefined && v !== false)
    .join(" ")
    .trim()

/**
 * Formats the given date as MM.DD.YYYY
 * @param {Date} dateObject a Date object
 */
export const formatDate = dateObject => {
  let stringParts = dateObject.toLocaleDateString().split("/")
  return stringParts.map(part => part.padStart(2, 0)).join(".")
}

/**
 * Formats the given date as March 3, 2020
 * @param {Date} dateObject a Date object
 */
export const formatDateLong = dateObject => {
  let dateArr = [...dateObject.toDateString().replace(/^\D{3}\s/, "")]
  dateArr.splice(-5, 0, ",")
  return dateArr.join("")
}

export const fluidImageWithWebp = graphql`
  fragment fluidImageWithWebp on ImageSharpFixed {
    width
    height
    src
    srcSet
    srcWebp
    srcSetWebp
  }
`

export const fluidImageWithDefault = graphql`
  fragment fluidImageWithDefault on ImageSharpFixed {
    width
    height
    src
    srcSet
  }
`

export const easeOutCubic = (t, b, c, d) => {
  return c * ((t = t / d - 1) * t * t + 1) + b
}

export const calculateAge = (birthDate, otherDate) => {
  birthDate = new Date(birthDate)
  otherDate = otherDate ? new Date(otherDate) : new Date()

  let years = otherDate.getFullYear() - birthDate.getFullYear()

  if (
    otherDate.getMonth() < birthDate.getMonth() ||
    (otherDate.getMonth() == birthDate.getMonth() &&
      otherDate.getDate() < birthDate.getDate())
  ) {
    years--
  }

  return years
}

export const getUrlParameter = (name, url) => {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]")
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)")
  var results = regex.exec(url || window.location.search)
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "))
}

// Promisified Alps calls
export const retrieveMissionStatus = async function(missionID) {
  if (isServer) return

  return new Promise((resolve, reject) =>
    window.Alps.Api.retrieveMissionStatus(missionID, (error, result) => {
      if (error) reject(error)
      else {
        // console.log(`missionID ${missionID} result:`, result)
        resolve(result)
      }
    })
  )
}

export const progressMissionStatus = async function(missionID) {
  if (isServer) return

  try {
    const result = await new Promise((resolve, reject) => {
      window.Alps.Api.progressMissionStatus(
        missionID,
        (isSuccess, httpStatus, errorCode) => {
          if (isSuccess) {
            // console.log(`progressed mission ${missionID}`)
            resolve(true)
          } else reject({ errorCode, httpStatus })
        }
      )
    })

    return result
  } catch (err) {
    return err
  }
}

/**
 * Returns a *new* randomized array.
 * Using Durstenfeld shuffle algorithm.
 */
export const shuffleArray = originalArray => {
  let newArray = [].concat(originalArray)

  for (let i = newArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    const temp = newArray[i]

    newArray[i] = newArray[j]
    newArray[j] = temp
  }

  return newArray
}
