import React from "react"
import PropTypes from "prop-types"

import { classnames } from "../../utils/helpers"

import styles from "./icon.module.scss"

const Icon = ({ id, className }) => {
  return (
    <svg
      className={classnames([styles.Icon, className])}
      aria-hidden="true"
      role="img"
    >
      <use xlinkHref={`#icon-${id}`}></use>
    </svg>
  )
}

Icon.defaultProps = {
  id: "",
  className: "",
}

Icon.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default Icon
