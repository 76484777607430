import { useContext } from "react"

import ReduceMotionContext from "../contexts/reduce-motion-context"

/**
 * useReduceMotion
 * Custom hook to be used whenever you need to read and update the reduce motion state.
 * This is basically a small wrapper around the ReduceMotionContext to make it more readable.
 *
 * Examples:
 * const [state, on, off] = useReduceMotion();
 *
 * To turn reduce motion on
 * on();
 *
 * To turn it off
 * off()
 *
 * Update your component when the state updates
 * useEffect(() => {
 *   console.log(state)
 * }, [state])
 */
export default function useReduceMotion() {
  const [state, dispatch, getInitState] = useContext(ReduceMotionContext)

  const on = function() {
    dispatch({ type: "on" })
  }

  const off = function() {
    dispatch({ type: "off" })
  }

  return [state, on, off, getInitState]
}
