import { useContext } from "react"

import LocaleContext from "../contexts/locale-context"

import globalENYaml from "../i18n/en.yaml"
import globalCAYaml from "../i18n/ca.yaml"
import globalESYaml from "../i18n/es.yaml"
import globalFRYaml from "../i18n/fr.yaml"

const LOCALES = {
  en: globalENYaml,
  es: globalESYaml,
  fr: globalFRYaml,
  ca: globalCAYaml,
}

const ISO_LANG = {
  en: "en-us",
  ca: "en-ca",
  fr: "fr-ca",
  es: "es-la",
}

const CONTENTFUL_LANG = {
  en: "en-US",
  ca: "en-CA",
  fr: "fr-CA",
  es: "es-MX",
}

export default function useLocale() {
  const langKey = useContext(LocaleContext)

  return {
    langKey,
    globalLocale: LOCALES[langKey],
    isoLangKey: ISO_LANG[langKey],
    contentfulLang: CONTENTFUL_LANG[langKey],
  }
}
