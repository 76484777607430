import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import { withPrefix } from "gatsby"

import { LocaleProvider } from "../contexts/locale-context"
import { ReduceMotionProvider } from "../contexts/reduce-motion-context"
import { NcloodProvider } from "../contexts/nclood-context"

import SVGIncludes from "../components/svg-includes/svg-includes"
import Footer from "../components/footer/footer"

const ncloodLangMap = { en: "en-us", es: "es-la", fr: "fr-ca", ca: "en-ca" }
const Wrapper = ({ children, langKey }) => {
  useEffect(() => {
    const mount = () => {
      window.nclood.mount("nclood-micro-nav")
      const footer = new nclood.Footer({
        color: "light",
        esrb: "e-e10",
        interestBasedAds: true,
      })
      document.getElementById("nclood-footer").appendChild(footer)
      window.Alps.mountRiot("alps-account-nav")
    }
    const check = () => {
      if (window.nclood && window.Alps) mount()
      else
        setTimeout(() => {
          check()
        }, 300)
    }
    check()
  }, [])

  const WTC_prefix = withPrefix("/")

  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          href={`https://media.nintendo.com/share/nclood/stable/${ncloodLangMap[langKey]}/bundles/microsite.css`}
        />
        <script
          src={`https://media.nintendo.com/share/nclood/stable/${ncloodLangMap[langKey]}/bundles/microsite.min.js`}
        ></script>
      </Helmet>
      <SVGIncludes />
      <nclood-micro-nav />
      <LocaleProvider langKey={langKey}>
        <NcloodProvider>
          <ReduceMotionProvider>
            <div id="website-wrap" data-locale={langKey}>
              {children}
              <Footer />
            </div>
          </ReduceMotionProvider>
        </NcloodProvider>
      </LocaleProvider>
    </>
  )
}

export const wrapPageElement = ({
  element,
  props: {
    pageContext: { langKey },
  },
}) => {
  return <Wrapper langKey={langKey}>{element}</Wrapper>
}
